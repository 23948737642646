export const convertToSlug = (Text) => {
  return (
    "/" +
    Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-")
  )
}

export const convertToClass = (Text) => {
  return (
      Text.toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-")
  )
}

export const formatPhoneString = (Text) => {
  return Text.toLowerCase()
      .replace(/[\s.]+/g, "")
}

export const getPageFromURL = (url) => {
  const parts = url.split('/');
  // parts[0] will be an empty string because the path starts with '/'
  // so we get the second element which should be 'client' or the other word
  return parts[1];
}

export const mediaIsVideo = (string) => {
  if (typeof string === "string") {
    return !isNaN(string);
  }
}