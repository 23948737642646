import React from "react";
import {Field, Form, Formik} from "formik";
import styled from "styled-components";
import useBoundStore from "../../../stores/useBoundStore";
import { splits } from "./SplitLogo";

const Inner = styled.div`
  padding: 1rem;
`;

const LogoForm = () => {
  const {
    setSplitIndex,
    splitIndex,
    setRepeat,
    repeat,
    setOverlap,
    overlap,
    setGap,
    gap,
  } = useBoundStore();

  return (
    <Formik
      initialValues={{
        snap: splitIndex,
        repeat: repeat,
        overlap: overlap,
        gap: gap,
      }}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        setSubmitting(false)
      }}
    >
      {({handleSubmit, setFieldValue, values}) => (
        <Form
          onSubmit={handleSubmit}
          onChange={event => {
            // console.log(
            //   "name",
            //   event.target.name,
            //   " | value",
            //   event.target.value
            // );
            if (event.target.name === 'snap') {
              setSplitIndex(event.target.value)
            } else if (event.target.name === 'repeat') {
              setRepeat(event.target.value)
            } else if (event.target.name === 'overlap') {
              setOverlap(event.target.value)
            } else if (event.target.name === 'gap') {
              setGap(event.target.value)
            }
          }}
          className="formik-form">
          <Inner>
            <fieldset>
              <label htmlFor="snap">Snap</label>
              <Field name="snap" type="range" min="0" max={splits.length - 1} step="1" />
            </fieldset>
            <fieldset>
              <label htmlFor="repeat">Repeat</label>
              <Field name="repeat" type="range" min="0" max="150" step="1" />
            </fieldset>
            <fieldset>
              <label htmlFor="overlap">Overlap</label>
              <Field name="overlap" type="range" min="-100" max="50" step="10" />
            </fieldset>
            <fieldset>
              <label htmlFor="gap">Gap</label>
              <Field name="gap" type="range" min="0" max="10" step="1" />
            </fieldset>
          </Inner>
        </Form>
      )}
    </Formik>
  );
};

export default LogoForm;

