import React, { useMemo } from "react";
import styled from "styled-components";
import { Configure, InstantSearch } from "react-instantsearch";
import Header from "../atoms/Header";
import Search from "../atoms/Search";
import ResultsHolder from "../atoms/SearchResults/ResultsHolder";
import algoliasearch from "algoliasearch/lite";
import PropTypes from "prop-types";
import classNames from "classnames";
import useBoundStore from "../../stores/useBoundStore";
import { themeHome } from "../../utils/styling";

const Holder = styled.div`
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  background-color: ${themeHome.colors.primary};
  transition: transform 0.5s;
  transform: translateY(0);

  @media (${(props) => props.theme.breakpoints.md}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 2fr 2fr 3fr 2fr;
    padding: 0 1rem;
  }

  // Show/hide for mobile
  .search,
  .results {
    display: none;
    @media (${(props) => props.theme.breakpoints.md}) {
      display: block;
    }
  }

  // Handle state when on home page
  &.isHome {
    .search,
    .results {
      display: block;
    }
    @media (${(props) => props.theme.breakpoints.md}) {
      .logo {
      }
      .search {
        grid-column: 2 / 4;
      }
      .results {
        grid-column: 4 / 6;
      }
    }
  }
  &.isPage {
    .card {
      .title {
        ${(props) => props.theme.fluidType(-3)}
      }
      .blurb p {
        ${(props) => props.theme.fluidType(-6)}
      }
      .type-pill {
        margin-bottom: 0.5rem;
        padding: 0.1rem 0.5rem 0.1rem 0.5rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
        .code {
          ${(props) => props.theme.fluidType(-7)};
        }
      }
    }
  }
`;

const Column = styled.div`
  padding: 0 1rem 0 1rem;
  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 1rem 0 0 0;
    max-height: 100%;
    overflow: scroll;
    overscroll-behavior: none;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  &.logo {
    overflow: hidden;
    @media (${(props) => props.theme.breakpoints.md}) {
      padding: 1.25vw 0 0 0;
    }
  }
  &.search {
    color: ${(props) => props.theme.colors.text};
    @media (${(props) => props.theme.breakpoints.md}) {
      grid-column: 2 / 3;
      background-color: transparent;
    }
  }
  &.results {
    @media (${(props) => props.theme.breakpoints.md}) {
      padding: 1rem 0 calc(var(--tickerHeight) + 1rem) 0;
      grid-column: 5 / 6;
    }
  }
`;

function SearchInterface(props) {
  const { searchActive } = useBoundStore();

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

  const holderClasses = classNames({
    isHome: props.isHome,
    isPage: !props.isHome,
    searchActive: searchActive,
  });

  return (
    <InstantSearch searchClient={searchClient} indexName="Pages">
      <Configure hitsPerPage={1000} />

      <Holder className={holderClasses}>
        <Column className="logo">
          <Header isHome={props.isHome} />
        </Column>
        <Column className="search">
          <Search />
        </Column>
        <Column className="results">
          <ResultsHolder isHome={props.isHome} />
        </Column>
      </Holder>
    </InstantSearch>
  );
}

SearchInterface.propTypes = {
  isHome: PropTypes.bool.isRequired,
};

export default SearchInterface;
