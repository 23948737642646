export const createLogoSlice = (set) => ({
  editingLogo: false,
  setEditingLogo: (value) => set({ editingLogo: value }),
  splitIndex: 0,
  setSplitIndex: (value) => set({ splitIndex: value }),
  repeat: 30,
  setRepeat: (value) => set({ repeat: value }),
  overlap: 0,
  setOverlap: (value) => set({ overlap: value }),
  gap: 0,
  setGap: (value) => set({ gap: value }),
})