import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  background-color: var(--bgColor);
  color: white;
  height: 2em;
  display: flex;
  align-items: center;
  padding: 0 1em;
  border-radius: ${(props) => (props.roundedCorners ? "500px" : "0")};
  transform: rotate(${(props) => props.rotation}deg);

  p {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1em;
    margin: 0;
    line-height: 1;
    color: var(--fgColor);
  }
`;

function LogoPiece({text, rotation, fgColor, bgColor, rounded}) {
  return (
    <Holder
      roundedCorners={rounded}
      rotation={rotation}
      style={{
        "--fgColor": fgColor,
        "--bgColor": bgColor,
      }}
    >
      <p>{text}</p>
    </Holder>
  );
}

LogoPiece.propTypes = {
  text: PropTypes.string.isRequired,
  rotation: PropTypes.number.isRequired,
  fgColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  rounded: PropTypes.bool.isRequired,
};

export default LogoPiece;

