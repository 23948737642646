import React from "react";
import styled from "styled-components";
import LogoPiece from "./LogoPiece";
import PropTypes from "prop-types";
import useBoundStore from "../../../stores/useBoundStore";
import { primaryColors, highlightColors } from "../../../utils/styling";

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.gap}em;
  position: absolute;
  top: 0;
  left: 0;
  translate: 0 ${(props) => props.yTransform}%;
  &:first-child {
    transform: none;
  }
`;

export const splits = [
  ["supermarket."],
  ["super", "market."],
  ["super", "mar", "ket."],
  ["su", "per", "mar", "ket."],
  ["su", "per", "mar", "ke", "t."],
  ["su", "pe", "r", "mar", "ke", "t."],
  ["su", "pe", "r", "ma", "r", "ke", "t."],
  ["su", "pe", "r", "ma", "r", "k", "e", "t."],
  ["s", "u", "p", "e", "r", "m", "a", "r", "k", "e", "t", "."],
];

function Split({split, index}) {

  // Save attributes as memoized values
  const rotation = React.useMemo(() => Math.floor(Math.random() * 31) - 15, []);
  const rounded = React.useMemo(() => Math.random() < 0.5, []);
  const color = React.useMemo(() => Math.floor(Math.random() * primaryColors.length), []);

  return (
    <LogoPiece
      key={index}
      text={split}
      rotation={rotation}
      bgColor={highlightColors[color]}
      fgColor={primaryColors[color]}
      rounded={rounded}
    />
  );
}

function SplitLogo(props) {

  const {
    splitIndex,
    overlap,
    gap ,
  } = useBoundStore();

  return (
    <Holder
      gap={gap/10}
      className="split-logo"
      yTransform={props.repeat * 100 + props.repeat * overlap}
    >
      {splits[splitIndex].map((split, index) => <Split key={index} split={split} index={index}/>)}
    </Holder>
  );
}

SplitLogo.propTypes = {
  repeat: PropTypes.number.isRequired,
};

export default SplitLogo;

