import React from "react";
import About from "../../assets/svg/icons/about.inline.svg";
import Projects from "../../assets/svg/icons/projects.inline.svg";
import Products from "../../assets/svg/icons/products.inline.svg";
import Partners from "../../assets/svg/icons/partners.inline.svg";
import Team from "../../assets/svg/icons/team.inline.svg";
import Clients from "../../assets/svg/icons/clients.inline.svg";
import Tech from "../../assets/svg/icons/tech.inline.svg";
import styled from "styled-components";
import { colorSchemeThemes } from "../../utils/styling";

const icons = {
  about: About,
  client: Clients,
  partner: Partners,
  product: Products,
  project: Projects,
  team: Team,
  tech: Tech,
};

const Holder = styled.div`
  svg {
    display: block;
    path, ellipse, rect {
      fill: ${(props) => props.fill};
    }
  }
`;

function Icon(props) {
  const fill = colorSchemeThemes[props?.type].colors.highlight;
  const IconType = icons?.[props?.type];
  return IconType && <Holder fill={fill}><IconType/></Holder>;
}

export default Icon;
