import React, { useState } from "react";
import { useRefinementList } from "react-instantsearch";
import styled from "styled-components";
import { colorSchemeThemes, themeHome } from "../../utils/styling";
import Icon from "./Icons";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: bold;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  h2 {
    margin-bottom: 0 !important;
  }
`;

const Clear = styled.button`
  display: ${({ isFilterActive }) => (isFilterActive ? "block" : "none")};
  ${(props) => props.theme.fluidType(-2)}
  text-decoration: underline;
  margin-left: 1rem;
  font-style: italic;
  color: inherit;
`;

const FilterWrapper = styled.div`
  ul {
    width: 100%;
    list-style: none;
    padding: 0;
  }
`;

const FilterButton = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.1rem 0.8rem;
  border-radius: 999px;
  line-height: 1.8;
  background-color: ${({ colors }) => colors.primary};
  color: ${({ colors }) => colors.text};
  border: 1px solid transparent;
  ${(props) => props.theme.fluidType(-3)}

  &.active {
    border: 1px solid ${({ colors }) => colors.text};
  }

  &:hover {
    opacity: 0.8;
  }

  svg {
    z-index: 3;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    path,
    ellipse,
    rect {
      fill: ${({ colors }) => colors.highlight};
    }
  }

  input {
    z-index: 1;
    appearance: none;
    position: absolute;
    inset: 0;

    margin: 0;
    border-radius: 999px;

    cursor: pointer;
  }

  span {
    position: relative;
    width: 100%;
    z-index: 2;
    display: block;
    pointer-events: none;
    user-select: none;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;

    @media (${({ theme }) => theme.breakpoints.md}) {
      font-size: unset;
    }
  }
`;

const FilterList = ({ attribute, sortBy }) => {
  const { items, refine } = useRefinementList({
    attribute,
    sortBy,
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  const clearFilters = () => {
    items.forEach((item) => {
      if (item.isRefined) {
        refine(item.value);
      }
    });
    setActiveFilters([]);
  };

  const handleFilterChange = (value) => {
    refine(value);
    if (activeFilters.includes(value)) {
      setActiveFilters(activeFilters.filter((item) => item !== value));
    } else {
      setActiveFilters([...activeFilters, value]);
    }
  };

  return (
    <Container>
      <Heading>
        <h2>Filters</h2>
        <Clear onClick={clearFilters} isFilterActive={activeFilters.length > 0}>
          Clear filters
        </Clear>
      </Heading>

      <FilterWrapper active={isExpanded}>
        <div>
          <ul>
            {items.map((item) => (
              <li key={item.label}>
                <FilterButton
                  colors={colorSchemeThemes[item.label].colors}
                  className={item.isRefined ? "active" : "inactive"}
                >
                  <Icon type={item.label} />
                  <input
                    type="checkbox"
                    checked={item.isRefined}
                    onChange={() => {
                      handleFilterChange(item.value);
                    }}
                  />
                  <span>{item.label}</span>
                </FilterButton>
              </li>
            ))}
          </ul>
        </div>
      </FilterWrapper>
    </Container>
  );
};

export default FilterList;
