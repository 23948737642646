const type = {
  typography: {
    min: 12,
    max: 16,
    minScreen: 375,
    maxScreen: 1600,
    scale: {
      min: 1.067,
      max: 1.067,
    },
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: (exp) => {
    const { minScreen, maxScreen, scale, min, max } = type.typography;

    // Calculate the minimum and maximum font sizes based on the provided exponent and the theme's scale values.
    const minFontSize = min * Math.pow(scale.min, exp);
    const maxFontSize = max * Math.pow(scale.max, exp);

    // Calculate the difference between the maximum and minimum font sizes.
    const fontSizeDiff = maxFontSize - minFontSize;

    // Calculate the difference between the maximum and minimum screen sizes.
    const screenSizeDiff = maxScreen - minScreen;

    // Return a CSS string that sets the font size to a responsive value.
    return `
      font-size: ${minFontSize}px;

      @media screen and (min-width: ${minScreen}px) {
        font-size: calc(${minFontSize}px + ${fontSizeDiff} * (100vw - ${minScreen}px) / ${screenSizeDiff});
      }

      @media screen and (min-width: ${maxScreen}px) {
        font-size: ${maxFontSize}px;
      }
    `;
  },
  breakpoints: {
    xs: "min-width: 375px",
    sm: "min-width: 428px",
    md: "min-width: 668px",
    lg: "min-width: 1024px",
    xl: "min-width: 1728px",
    xxl: "min-width: 1920px",
  },
};


// Primary colors: Card BG
// Text color: Page text
// Highlight color: Shape icon colour

const baseButtonColor = "#e1eaf3";

export const themeAbout = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#ffdd00",
  },
  ...type,
}

export const themeClient = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#1C5350",
  },
  ...type,
}

export const themePartner = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#0050E3",
  },
  ...type,
}

export const themeProduct = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#910742",
  },
  ...type,
}

export const themeProject = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#FF4218",
  },
  ...type,
}

export const themeTeam = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#1DD33E",
  },
  ...type,
}

export const themeTech = {
  colors: {
    primary: baseButtonColor,
    text: "#000000",
    highlight: "#FF8CF4",
  },
  ...type,
}

export const themeHome = {
  colors: {
    primary: "#f6f6f6",
    highlight: "#eef5ff",
    text: "#000000",
  },
  ...type,
}

export const colorSchemeThemes = {
  about: themeAbout,
  client: themeClient,
  partner: themePartner,
  product: themeProduct,
  project: themeProject,
  team: themeTeam,
  tech: themeTech,
  home: themeHome,
}

export const primaryColors = [
  themeAbout.colors.primary,
  themeClient.colors.primary,
  themePartner.colors.primary,
  themeProduct.colors.primary,
  themeProject.colors.primary,
  themeTeam.colors.primary,
  themeTech.colors.primary
];

export const highlightColors = [
  themeAbout.colors.highlight,
  themeClient.colors.highlight,
  themePartner.colors.highlight,
  themeProduct.colors.highlight,
  themeProject.colors.highlight,
  themeTeam.colors.highlight,
  themeTech.colors.highlight
];

export const textColors = [
  themeAbout.colors.text,
  themeClient.colors.text,
  themePartner.colors.text,
  themeProduct.colors.text,
  themeProject.colors.text,
  themeTeam.colors.text,
  themeTech.colors.text
];
