import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import styled, { ThemeProvider } from "styled-components";
import "../utils/fontface.css";

import useBoundStore from "../stores/useBoundStore";

import GlobalStyles from "../components/atoms/GlobalStyles";
import OpeningOverlay from "../components/atoms/OpeningOverlay";

import { colorSchemeThemes } from "../utils/styling";
import SearchInterface from "../components/organisms/SearchInterface";
import TickerTape from "../components/atoms/TickerTape";
import { getPageFromURL } from "../utils/helpers";
import LogoInterface from "../components/molecules/LogoInterface";

const Holder = styled.main`
  color: ${(props) => props.theme.colors.text};
`;

const ContentHolder = styled.div`
  padding: 1rem;
  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 3fr 2fr;
    gap: 1rem;
  }
`;

const ContentInner = styled.div`
  padding-bottom: var(--tickerHeight);
  position: relative;
  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 0;
    grid-column: 3 / 5;
  }
`;

const Index = ({ children }) => {
  const location = useLocation();
  const { colorScheme, setColorScheme } = useBoundStore();
  const isHome = location.pathname === "/";

  useEffect(() => {
    if (location.pathname === "/") {
      setColorScheme("home");
    } else {
      setColorScheme(getPageFromURL(location.pathname));
    }
  }, [location, colorScheme, setColorScheme]);

  return (
    <ThemeProvider theme={colorSchemeThemes[colorScheme]}>
      <>
        <GlobalStyles />
        <Holder>
          <OpeningOverlay />
          <LogoInterface />
          <SearchInterface isHome={isHome} />
          <ContentHolder>
            <ContentInner>{children}</ContentInner>
          </ContentHolder>
          <TickerTape />
        </Holder>
      </>
    </ThemeProvider>
  );
};

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
