import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useSearchBox } from "react-instantsearch";
import styled from "styled-components";
import { useLocation } from "@reach/router";

import Cross from "../../assets/svg/icons/cross.inline.svg";

const Holder = styled.form`
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 0;

  input {
    width: 100%;

    border: 1px solid ${(props) => props.theme.colors.text};
    background-color: transparent;
    @media (${({ theme }) => theme.breakpoints.md}) {
      border: 1px solid ${(props) => props.theme.colors.text};
    }

    font-weight: 500;
    &::placeholder {
      opacity: 0.4;
      text-transform: capitalize;
      color: ${(props) => props.theme.colors.text};

      @media (${({ theme }) => theme.breakpoints.md}) {
        color: ${(props) => props.theme.colors.text};
      }
    }

    &:focus {
      & + button {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
`;

const ClearSearch = styled.button`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);

  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? "all" : "none")};
  transition: opacity 0.3s ease-in-out;

  svg {
    width: 0.625rem;
    height: 0.625rem;

    color: ${(props) => props.theme.colors.text};
  }

  &:focus {
    opacity: 1;
    pointer-events: all;
  }
`;

const SearchBox = ({ className }) => {
  const { query, refine } = useSearchBox();
  const location = useLocation();
  const isHome = location.pathname === "/";

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const clearSearch = () => {
    refine("");
  };

  //Clear search on return home

  useEffect(() => {
    if (isHome) {
      clearSearch();
    }
  }, [location]);

  // Check for 'search' query parameter when component mounts
  useEffect(() => {
    if (typeof window === "undefined") return;

    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get("search");
    if (searchParam) {
      refine(searchParam);
    }
  }, [refine]);

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        clearSearch();
        navigate("/");
      }
    };

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <Holder className={className} onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Search..."
        aria-label="Search"
        onChange={(e) => refine(e.target.value)}
        value={query}
      />

      <ClearSearch onClick={clearSearch} active={query}>
        <Cross />
      </ClearSearch>
    </Holder>
  );
};

export default SearchBox;

