import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  width: 100%;
  svg,
  img {
    width: 100%;
    height: ${(props) => (props.alignTop ? "auto" : "100%")};
    object-fit: contain;
    path {
      fill: ${(props) => props.fill};
    }
  }
`;

function ClientLogo({ logo, fill, alignTop }) {
  return (
    <Holder fill={fill} alignTop={alignTop}>
      <img src={logo} alt="client logo" />
    </Holder>
  );
}

ClientLogo.propTypes = {
  logo: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  alignTop: PropTypes.bool,
};

ClientLogo.defaultProps = {
  alignTop: false,
};

export default ClientLogo;
