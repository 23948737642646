import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import useBoundStore from "../../stores/useBoundStore";
import TechImage from "./TechImage";
import ClientLogo from "../atoms/ClientLogo";
import { colorSchemeThemes } from "../../utils/styling";
import classNames from "classnames";
import TypePill from "../atoms/TypePill";

const Wrapper = styled.div`
  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      ${(props) => props.theme.fluidType(3)};
      margin-bottom: 0.5rem;
    }
  }

  .image-holder {
    display: block;
  }
  .blurb {
    display: block;
  }
`;

const Image = styled.div`
  width: 100%;
  display: none;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Blurb = styled.div`
  display: none;
  margin-bottom: 1rem;
  p {
    ${(props) => props.theme.fluidType(-1)}
  }
`;

const Card = ({ data }) => {
  const { closeSearch } = useBoundStore();

  const { category, slug, thumbnail, blurb, title, tagline, position, imageIcons, imageColors } = data;
  const colors = colorSchemeThemes[category].colors;

  const checkShowImage = (category) => {
    return category !== "about" && category !== "tech" && category !== "client";
  };

  if (category === "client" && slug === "internal") {
    return null;
  }

  const wrapperClasses = classNames('card', `card-wrapper-${category}`);
  return (
    <Wrapper className={wrapperClasses} colors={colors}>
      <Link to={`/${category}/${slug}`} onClick={() => closeSearch()}>
        <TypePill type={category} />
        <h3 className="title">{tagline ? tagline : title}</h3>
        {position && <p>{position}</p>}
        <Blurb className="blurb">
          <p>{blurb}</p>
        </Blurb>
        <Image className="image-holder">
          {category === "client" && (
            <ClientLogo logo={thumbnail} fill={colors.text} />
          )}
          {checkShowImage(category) && <img src={thumbnail} alt="" />}
          {category === "tech" && <TechImage imageIcons={imageIcons} imageColors={imageColors}/>}
        </Image>
      </Link>
    </Wrapper>
  );
};

Card.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Card;
