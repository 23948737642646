import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Pill from "../../assets/svg/icons/pill.inline.svg";
import Rect from "../../assets/svg/icons/rect.inline.svg";
import Sphere from "../../assets/svg/icons/sphere.inline.svg";
import Triangle from "../../assets/svg/icons/triangle.inline.svg";
import Stack from "../../assets/svg/icons/stack.inline.svg";
import Star from "../../assets/svg/icons/star.inline.svg";
import SemiCircle from "../../assets/svg/icons/semiCircle.inline.svg";
import { primaryColors, highlightColors } from "../../utils/styling";

const icons = [Pill, Rect, Sphere, Triangle, Stack, Star, SemiCircle];

const Holder = styled.div`
    width: 100%;
    aspect-ratio: 1/1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        stroke: #000;
        stroke-width: 1;
        vector-effect: non-scaling-stroke;
        overflow: visible;
        path,
        circle,
        line,
        ellipse,
        rect {
            vector-effect: non-scaling-stroke;
        }
        &.color-1 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[0]};
            }
        }
        &.color-2 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[1]};
            }
        }
        &.color-3 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[2]};
            }
        }
        &.color-4 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[3]};
            }
        }
        &.color-5 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[4]};
            }
        }
        &.color-6 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[5]};
            }
        }
        &.color-7 {
            path,
            circle,
            line,
            ellipse,
            rect {
                fill: ${highlightColors[6]};
            }
        }
    }
}

.set1 {
    grid-column: span 2;
    grid-row: span 2;
    &.set1-1 {
        grid-column: 1/3;
        grid-row: 1/3;
    }
    &.set1-2 {
        grid-column: 3/5;
        grid-row: 1/3;
    }
    &.set1-3 {
        grid-column: 7/9;
        grid-row: 1/3;
    }
    &.set1-4 {
        grid-column: 9/11;
        grid-row: 1/3;
    }
    &.set1-5 {
        grid-column: 1/3;
        grid-row: 3/5;
    }
    &.set1-6 {
        grid-column: 9/11;
        grid-row: 3/5;
    }
    &.set1-7 {
        grid-column: 5/7;
        grid-row: 5/7;
    }
    &.set1-8 {
        grid-column: 1/3;
        grid-row: 7/9;
    }
    &.set1-9 {
        grid-column: 9/11;
        grid-row: 7/9;
    }
    &.set1-10 {
        grid-column: 1/3;
        grid-row: 9/11;
    }
    &.set1-11 {
        grid-column: 3/5;
        grid-row: 9/11;
    }
    &.set1-12 {
        grid-column: 7/9;
        grid-row: 9/11;
    }
    &.set1-13 {
        grid-column: 9/11;
        grid-row: 9/11;
    }
}
.set2 {
    grid-column: span 2;
    grid-row: span 2;
    &.set2-1 {
        grid-column: 5/7;
        grid-row: 1/3;
    }
    &.set2-2 {
        grid-column: 1/3;
        grid-row: 5/7;
    }
    &.set2-3 {
        grid-column: 9/11;
        grid-row: 5/7;
    }
    &.set2-4 {
        grid-column: 5/7;
        grid-row: 9/11;
    }
}
.set3 {
    grid-column: span 2;
    grid-row: span 2;
    &.set3-1 {
        grid-column: 5/7;
        grid-row: 3/5;
    }
    &.set3-2 {
        grid-column: 3/5;
        grid-row: 5/7;
    }
    &.set3-3 {
        grid-column: 7/9;
        grid-row: 5/7;
    }
    &.set3-4 {
        grid-column: 5/7;
        grid-row: 7/9;
    }
}
.set4 {
    grid-column: span 2;
    grid-row: span 2;
    mix-blend-mode: hard-light;
    &.set4-1 {
        grid-column: 2/5;
        grid-row: 2/5;
    }
    &.set4-2 {
        grid-column: 7/10;
        grid-row: 2/5;
    }
    &.set4-3 {
        grid-column: 2/5;
        grid-row: 7/10;
    }
    &.set4-4 {
        grid-column: 7/10;
        grid-row: 7/10;
    }
}
.set5 {
    mix-blend-mode: hard-light;
    grid-column: span 2;
    grid-row: span 2;
    &.set5-1 {
        grid-column: 5/7;
        grid-row: 1/3;
    }
    &.set5-2 {
        grid-column: 1/3;
        grid-row: 5/7;
    }
    &.set5-3 {
        grid-column: 9/11;
        grid-row: 5/7;
    }
    &.set5-4 {
        grid-column: 5/7;
        grid-row: 9/11;
    }
}
.set6 {
    grid-column: 4/8;
    grid-row: 4/8;
    mix-blend-mode: color-dodge;
}
`;

function TechImage({ title, imageIcons, imageColors }) {
  let configArray = [];

  //if imageIcons and imageColors are an array of values then set up the tech image config object
  if (Array.isArray(imageIcons) && Array.isArray(imageColors)) {
    configArray = imageIcons.map((icon, index) => {
      return {
        icon: icon,
        color: imageColors[index]
      };
    });
  }

  let icon1Index, icon2Index, icon3Index, icon4Index, icon5Index, icon6Index;
  let colorIndex1, colorIndex2, colorIndex3, colorIndex4, colorIndex5, bgColor;
  if(configArray.length > 0) {
    icon1Index = configArray[0].icon;
    icon2Index = configArray[1].icon;
    icon3Index = configArray[2].icon;
    icon4Index = configArray[3].icon;
    icon5Index = configArray[4].icon;
    icon6Index = configArray[5].icon;

    colorIndex1 = configArray[0].color;
    colorIndex2 = configArray[1].color;
    colorIndex3 = configArray[2].color;
    colorIndex4 = configArray[3].color;
    colorIndex5 = configArray[4].color;
    bgColor = configArray[5].color;
  } else {
    icon1Index = Math.floor(Math.random() * icons.length);
    icon2Index = Math.floor(Math.random() * icons.length);
    icon3Index = Math.floor(Math.random() * icons.length);
    icon4Index = Math.floor(Math.random() * icons.length);
    icon5Index = Math.floor(Math.random() * icons.length);
    icon6Index = Math.floor(Math.random() * icons.length);

    colorIndex1 = Math.floor(Math.random() * primaryColors.length);
    colorIndex2 = Math.floor(Math.random() * primaryColors.length);
    colorIndex3 = Math.floor(Math.random() * primaryColors.length);
    colorIndex4 = Math.floor(Math.random() * primaryColors.length);
    colorIndex5 = Math.floor(Math.random() * primaryColors.length);
    bgColor = Math.floor(Math.random() * highlightColors.length);
  }
  let Icon1, Icon2, Icon3, Icon4, Icon5, Icon6;
  Icon1 = icons[icon1Index];
  Icon2 = icons[icon2Index];
  Icon3 = icons[icon3Index];
  Icon4 = icons[icon4Index];
  Icon5 = icons[icon5Index];
  Icon6 = icons[icon6Index];

  const set1 = [];
  for (let i = 0; i < 13; i++) {
    set1.push(
      <Icon1 className={`set1 set1-${i + 1} color-${colorIndex1}`} key={i} />
    );
  }

  const set2 = [];
  for (let i = 0; i < 4; i++) {
    set2.push(
      <Icon2 className={`set2 set2-${i + 1} color-${colorIndex2}`} key={i} />
    );
  }

  const set3 = [];
  for (let i = 0; i < 4; i++) {
    set3.push(
      <Icon3 className={`set3 set3-${i + 1} color-${colorIndex3}`} key={i} />
    );
  }

  const set4 = [];
  for (let i = 0; i < 4; i++) {
    set4.push(
      <Icon4 className={`set4 set4-${i + 1} color-${colorIndex4}`} key={i} />
    );
  }

  const set5 = [];
  for (let i = 0; i < 4; i++) {
    set5.push(
      <Icon5 className={`set5 set5-${i + 1} color-${colorIndex5}`} key={i} />
    );
  }



  return (
    <Holder style={{backgroundColor: highlightColors[bgColor]}} onClick={() => {
      console.log("Clicked");
      const myArray = [
        { icon: icon1Index, color: colorIndex1 },
        { icon: icon2Index, color: colorIndex2 },
        { icon: icon3Index, color: colorIndex3 },
        { icon: icon4Index, color: colorIndex4 },
        { icon: icon5Index, color: colorIndex5 },
        { icon: icon6Index, color: bgColor }
      ]
      console.log(myArray);
    }}>
      {set1}
      {set2}
      {set3}
      {set4}
      {set5}
      <Icon6 className={`set6 color-${colorIndex1}`} />
    </Holder>
  );
}

TechImage.propTypes = {
  title: PropTypes.string,
  imageIcons: PropTypes.array,
  imageColors: PropTypes.array,
};

export default TechImage;
