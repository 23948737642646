import React from "react";
import { Index, useHits } from "react-instantsearch";
import PropTypes from "prop-types";
import styled from "styled-components";
import Card from "../../molecules/Card";

const ResultsGrid = styled.ul`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  list-style: none;
  gap: 2rem;
  margin: 0;

  &.isHome {
    @media (${(props) => props.theme.breakpoints.lg}) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 4rem 2rem;
    }
  }
`;

const IndexHits = (props) => {
  const { hits } = useHits();

  return (
    <Index indexName="Pages">
      <ResultsGrid className={props.isHome ? "isHome" : ""}>
        {hits.map((hit) => (
          <Card key={hit.objectID} data={hit} />
        ))}
      </ResultsGrid>
    </Index>
  );
};

IndexHits.propTypes = {
  isHome: PropTypes.bool.isRequired,
};

export default IndexHits;
