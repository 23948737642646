import { create } from "zustand";
import { createColorSlice } from "./colorSlice";
import { createSearchSlice } from "./searchSlice";
import { createLogoSlice } from "./logoSlice";

const useBoundStore = create((...a) => ({
  ...createColorSlice(...a),
  ...createSearchSlice(...a),
  ...createLogoSlice(...a),
}));

export default useBoundStore;
