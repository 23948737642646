import React, { useState } from "react";
import styled from "styled-components";

const Holder = styled.div`
  position: fixed;
  height: var(--windowHeight);
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(255,255,255, 0.4);
  backdrop-filter: blur(10px);

  padding: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 50rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  p {
    ${(props) => props.theme.fluidType(6)};
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 2rem;
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    @media (${({ theme }) => theme.breakpoints.md}) {
      ${(props) => props.theme.fluidType(8)};
    }
  }
`;

const OpeningOverlay = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  if (!showOverlay) return null;

  return (
    <Holder onClick={() => setShowOverlay(false)}>
      <Inner>
        <p>
          SUPERMARKET DESIGN LTD.<br/>
          Code & Design Services<br/>
        </p>
        <p>Unit 4, Webbers Yard Estate<br/>
          Dartington, Totnes, Devon<br/>
          United Kingdom TQ9 6JY</p>
        <p>We make the complex simple.</p>
      </Inner>
    </Holder>
  );
};

export default OpeningOverlay;
