import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import SplitLogo from "./SplitLogo";
import useBoundStore from "../../../stores/useBoundStore";

const Holder = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.fontSize * 1.11}vw;
  }
`;

const Inner = styled.div`
  position: relative;
`;

function LogoHolder() {
  const {repeat, splitIndex, gap} = useBoundStore();
  const [ fontSize, setFontSize ] = useState( 0.84 );

  useEffect(() => {
    if (Number(splitIndex) === 0) {
      setFontSize( 0.84 );
    } else if (Number(splitIndex) === 1) {
      // Min: 0.64 / Max: 0.7 / Difference: 0.06
      // Gap multiplier: Difference / 10 = 0.006
      setFontSize( 0.7 - (0.006 * gap) );
    } else if (Number(splitIndex) === 2) {
      // Min: 0.52 / Max: 0.6
      // Difference: 0.08
      // Gap multiplier: Difference / 10 = 0.008
      setFontSize( 0.6 - (0.008 * gap) );
    } else if (Number(splitIndex) === 3) {
      // Min: 0.43 / Max: 0.52
      // Difference: 0.07
      // Gap multiplier: Difference / 10 = 0.007
      setFontSize( 0.52 - (0.007 * gap) );
    } else if (Number(splitIndex) === 4) {
      // Min: 0.38 / Max: 0.46
      // Difference: 0.08
      // Gap multiplier: Difference / 10 = 0.008
      setFontSize( 0.46 - (0.008 * gap) );
    } else if (Number(splitIndex) === 5) {
      // Min: 0.34 / Max: 0.42
      // Difference: 0.08
      // Gap multiplier: Difference / 10 = 0.008
      setFontSize( 0.42 - (0.008 * gap) );
    } else if (Number(splitIndex) === 6) {
      // Min: 0.3 / Max: 0.38
      // Difference: 0.08
      // Gap multiplier: Difference / 10 = 0.008
      setFontSize( 0.38 - (0.008 * gap) );
    } else if (Number(splitIndex) === 7) {
      // Min: 0.28 / Max: 0.36
      // Difference: 0.08
      // Gap multiplier: Difference / 10 = 0.008
      setFontSize( 0.36 - (0.008 * gap) );
    } else if (Number(splitIndex) === 8) {
      // Min: 0.2 / Max: 0.27
      // Difference: 0.07
      // Gap multiplier: Difference / 10 = 0.007
      setFontSize( 0.27 - (0.007 * gap) );
    }
  }, [splitIndex, gap]);

  const repeats = [];
  for (let i = 0; i <= repeat; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html

    repeats.push(<SplitLogo key={i} repeat={i} />);
  }

  // console.log(repeats, repeats);

  return <Holder fontSize={fontSize}>
    <Inner>{repeats}</Inner>
  </Holder>;
}

export default LogoHolder;