import React from "react";
import styled from "styled-components";
import useBoundStore from "../../stores/useBoundStore";
import SearchIcon from "../../assets/svg/icons/search.inline.svg";
import LogoHolder from "./LogoElements/LogoHolder";
import { Link } from "gatsby";
import { themeHome } from "../../utils/styling";
import Logo from "../../assets/png/supermarket-logo.png";
import PropTypes from "prop-types";

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 0;
  }

  .toggleSearch {
    background: ${themeHome.colors.highlight};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (${(props) => props.theme.breakpoints.md}) {
      display: none;
    }
    svg {
      width: 1rem;
      height: 1rem;
      path {
        stroke: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

const DesktopLogoButton = styled.button`
  display: none;
  @media (${(props) => props.theme.breakpoints.md}) {
    display: block;
    background-color: transparent;
    width: 100%;
  }
`;

const MobileLogoHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 3rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  @media (${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const Header = ({ isHome }) => {
  const { setEditingLogo, editingLogo } = useBoundStore();
  const goHome = () => {
    setEditingLogo(!editingLogo);
  };

  return (
    <Holder>
      <DesktopLogoButton onClick={goHome}>
        <LogoHolder />
      </DesktopLogoButton>
      <Link to="/">
        <MobileLogoHolder>
          <img src={Logo} alt="logo" style={{ width: "100%" }} />
        </MobileLogoHolder>
      </Link>
      {!isHome && (
        <Link to="/" className="toggleSearch">
          <SearchIcon />
        </Link>
      )}
    </Holder>
  );
};

Header.propTypes = {
  isHome: PropTypes.bool.isRequired,
};

export default Header;
