import React from "react";
import { useSearchBox } from "react-instantsearch";
import styled from "styled-components";

const Holder = styled.div``;

const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;

  button {
    display: inline-block;
    color: inherit;
    font-weight: 500;
    font-size: 1.4rem;
    &:hover {
      text-decoration: underline;
    }

    @media (${({ theme }) => theme.breakpoints.md}) {
      ${(props) => props.theme.fluidType(3)};
      font-weight: 700;
    }
  }
`;

const SuggestedSearch = () => {
  const searches = [
    "Meta",
    "Directors",
    "GSAP",
    "React-three-fiber",
    "Prototypes",
    "3D",
  ];

  const { refine } = useSearchBox();

  return (
    <Holder>
      <h2>Suggested Searches</h2>
      <ListWrapper>
        {searches.map((search, index) => (
          <li key={index}>
            <button onClick={() => refine(search)}>{search}</button>
          </li>
        ))}
      </ListWrapper>
    </Holder>
  );
};

export default SuggestedSearch;
