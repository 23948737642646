import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { colorSchemeThemes } from "../../utils/styling";
import Icon from "./Icons";
import classNames from "classnames";

const Holder = styled.span`
  align-self: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 0.1rem 1rem 0.1rem 1rem;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 1rem;
  
  &.has-icon {
    padding: 0.25rem 1rem 0.25rem 0.75rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .code {
    text-transform: capitalize;
    ${(props) => props.theme.fluidType(-5)};
  }
`;

function TypePill({type, showIcon, text, inline}) {
  const colors = colorSchemeThemes[type].colors;
  
  const holderClasses = classNames({
    'type-pill': true,
    'has-icon': showIcon,
    'inline': inline,
  });
  return (
    <Holder
      className={holderClasses}
      style={{
        color: showIcon ? colors.text : colors.highlight,
        backgroundColor: colors.primary,
        border: `1px solid ${colors.highlight}`,
        display: inline ? 'inline-flex' : 'flex',
      }}
    >
      {showIcon && <Icon type={type} />}
      <span className="code h2">{text ? text : type}</span>
    </Holder>
  );
}

TypePill.propTypes = {
  type: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
  inline: PropTypes.bool,
};

TypePill.defaultProps = {
  showIcon: true,
  text: null,
  inline: false,
};

export default TypePill;