import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import IndexHits from "./IndexHits";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ResultsHolder = ({ isHome }) => {
  return (
    <Wrapper>
      <IndexHits isHome={isHome} />
    </Wrapper>
  );
};

ResultsHolder.propTypes = {
  isHome: PropTypes.bool.isRequired,
};

export default ResultsHolder;

