import React, { forwardRef } from "react";
import styled from "styled-components";

import FilterList from "./FilterList";
import SuggestedSearch from "./SuggestedSearch";
import SearchBox from "./SearchBox";
import { themeHome } from "../../utils/styling";

const Container = styled.div`
  //background: ${themeHome.colors.highlight};
  color: ${themeHome.colors.text};
  //border-radius: 0.5rem;
  overflow: hidden;
  //padding: 1rem;
  .search-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 20rem;
  }

  @media (${({ theme }) => theme.breakpoints.md}) {
    gap: 2rem;
  }

  h2 {
    ${(props) => props.theme.fluidType(-2)}
    font-weight: 400;
    margin-bottom: 1rem;
  }
`;

const Search = forwardRef((_, ref) => {
  return (
    <Container ref={ref}>
      <div className="search-inner">
        <SearchBox />
        <SuggestedSearch />
        <FilterList attribute="category" sortBy={["name"]} />
      </div>
    </Container>
  );
});

export default Search;
