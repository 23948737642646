import React from "react";
import styled from "styled-components";
import LogoForm from "../atoms/LogoElements/LogoForm";
import { themeHome } from "../../utils/styling";
import useBoundStore from "../../stores/useBoundStore";

const BGHolder = styled.button`
  position: fixed;
  z-index: 90;
  top: 0;
  left: calc(10% + 0.5rem);
  right: 0;
  bottom: var(--tickerHeight);
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  cursor: pointer;
  border-radius: 0;
`;
const FormHolder = styled.div`
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${themeHome.colors.primary};
  padding: 2rem;
  display: none;
  @media (${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`;

function LogoInterface() {
  const { editingLogo, setEditingLogo } = useBoundStore();
  if (!editingLogo) return null;
  return (
    <>
      <BGHolder onClick={() => setEditingLogo(false)} />
      <FormHolder>
        <LogoForm />
      </FormHolder>
    </>
  );
}

export default LogoInterface;
